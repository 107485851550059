<ion-header>
    <ion-toolbar>

        <ion-title>Luoghi del sondaggio</ion-title>

        <ion-buttons slot="end">
            <ion-button (click)="doAbort()">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>

    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="region-banner">
        Puoi applicare il sondaggio a livello:
        <ul>
            <li><strong>Nazionale</strong> (tutti i punti vendita del progetto): in tal caso basta cliccare su applica, senza selezionare alcun campo</li>
            <li><strong>Regionale</strong>: in tal caso basta scegliere la regione e poi cliccare su applica</li>
            <li><strong>Provinciale</strong>: in tal caso basta scegliere la regione, poi la provincia e poi cliccare su applica</li>
            <li><strong>Comunale</strong>: in tal caso basta scegliere la regione, poi la provincia, poi il comune e infine cliccare su applica</li>
        </ul>
        Per applicare il sondaggio a più regioni o più province o più comuni <strong>è necessario compilare N volte questo form</strong>.<br />
        Alcuni esempi:
        <ul>
            <li>Voglio applicare il sondaggio alla <strong>Lombardia</strong> e alle <strong>Marche</strong>: in tal caso dovrò inserire 2 record, uno per la Lombardia e uno per le Marche</li>
            <li>Voglio applicare il sondaggio su <strong>Milano</strong> e <strong>Palermo</strong>: in tal caso dovrò inserire 2 record, uno per Milano e uno per Palermo</li>
        </ul>
    </div>
    <form action="" [formGroup]="form">
        <ion-item>
            <ion-label>Regione</ion-label>
            <ion-select (ionChange)="getProvinceByRegione()" formControlName="regions" placeholder="Seleziona...">
                <ion-select-option *ngFor="let regione of regions"
                                   value="{{regione.id}}">{{regione.name}}</ion-select-option>
            </ion-select>
        </ion-item>
        <ion-item>
            <ion-label>Provincia</ion-label>
            <ion-select (ionChange)="getComuniByProvincia()" [disabled]="provinces.length === 0"
                        formControlName="provinces"
                        placeholder="Seleziona...">
                <ion-select-option *ngFor="let provincia of provinces"
                                   value="{{provincia.id}}">{{provincia.name}}</ion-select-option>
            </ion-select>
        </ion-item>
        <ion-item>
            <ion-label>Comune</ion-label>
            <ion-select [disabled]="cities.length === 0" formControlName="cities" placeholder="Seleziona...">
                <ion-select-option *ngFor="let comune of cities"
                                   value="{{comune.id}}">{{comune.name}}</ion-select-option>
            </ion-select>
        </ion-item>
    </form>
</ion-content>

<ion-footer>
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-button expand="block" color="medium" fill="outline" (click)="doAbort()">Annulla</ion-button>
            </ion-col>
            <ion-col>
                <ion-button expand="block" color="success" (click)="doApply()">Applica</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>
