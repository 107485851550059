import * as tslib_1 from "tslib";
import { AlertController, LoadingController, ModalController, NavController, PopoverController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import axios from "axios";
import { BadRequest, InternalServerError, NotFound } from "ts-httpexceptions";
import { Storage } from "@ionic/storage";
/*
 * NOTA: Popover e Modal non possono essere usati in un servizio con providedIn 'root'
 *       NON USARE:     providedIn: 'root'
 *       E iniettare il servizio in commonmodule e usarlo da li
 */
export class UtilsService {
    constructor(loadingController, modalController, toastController, popoverController, alertController, router, nav, storage) {
        this.loadingController = loadingController;
        this.modalController = modalController;
        this.toastController = toastController;
        this.popoverController = popoverController;
        this.alertController = alertController;
        this.router = router;
        this.nav = nav;
        this.storage = storage;
        this._endpoint = null;
        this.showLoading = (message = 'Caricamento...', extra) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingController.create(Object.assign({ spinner: null, message: message, translucent: true, cssClass: 'custom-class custom-loading' }, extra));
            return yield loading.present();
        });
        this.showSpinner = (extra) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loading = yield this.loadingController.create(Object.assign({ message: null, cssClass: 'custom-class custom-loading' }, extra));
            return yield loading.present();
        });
        this.hideLoading = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.loadingController.dismiss();
            }
            catch (err) {
                console.debug('catched hideLoading', err);
            }
        });
        this.showModal = (component, componentProps, extra) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create(Object.assign({ component: component, componentProps: componentProps, backdropDismiss: false }, extra));
            //return await modal.present();
            yield modal.present();
            return yield modal.onWillDismiss();
        });
        this.showPopover = (event, component, componentProps) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverController.create({
                component: component,
                componentProps: componentProps,
                event: event,
                translucent: true
            });
            //return await popover.present();
            yield popover.present();
            return yield popover.onWillDismiss();
        });
        this.closeModal = (data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.modalController.dismiss(data);
        });
        this.showToast = (message, extra) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create(Object.assign({ message: message, position: 'bottom', duration: 2000 }, extra));
            if (extra.redirect) {
                toast.onDidDismiss().then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    yield this.hideLoading();
                    yield this.router.navigateByUrl(extra.redirect);
                }));
            }
            return yield toast.present();
        });
        /*
        
            public showToastOnErrorWithRedirect = async (message: string, path: string = '/dashboard') => {
                const toast = await this.toastController.create({
                    message: message,
                    showCloseButton: true,
                    position: 'bottom',
                    closeButtonText: 'Ok',
                    color: 'danger',
                    duration: 10000,
                });
        
                toast.onDidDismiss().then(async () => {
                    await this.hideLoading();
                    await this.router.navigateByUrl(path);
                });
        
                return await toast.present();
            };
        
        
            public showToastOnError = async (message: string) => {
                const toast = await this.toastController.create({
                    message: message,
                    duration: 10000,
                    showCloseButton: true,
                    position: 'bottom',
                    closeButtonText: 'Ok',
                    color: 'danger',
                });
        
                toast.onDidDismiss().then(async () => {
                    // other events
                });
        
                return await toast.present();
            };
        
        
            public showToastOnSuccess = async (message: string) => {
                const toast = await this.toastController.create({
                    message: message,
                    duration: 10000,
                    showCloseButton: true,
                    position: 'bottom',
                    closeButtonText: 'Ok',
                    color: 'success'
                });
        
                toast.onDidDismiss().then(async () => {
                    // other events
                });
        
                return await toast.present();
            };
        
        
            public showToastOnSuccessWithRedirect = async (message: string, path: string = '/dashboard') => {
                const toast = await this.toastController.create({
                    message: message,
                    duration: 10000,
                    showCloseButton: true,
                    position: 'bottom',
                    closeButtonText: 'Ok',
                    color: 'success'
                });
        
                toast.onDidDismiss().then(async () => {
                    await this.hideLoading();
                    await this.router.navigateByUrl(path);
                });
        
                return await toast.present();
            };
        */
        /**
         * NOTA: Non si dovrebbe cambiare il nome a funzioni già usate da altri
         * in Extra puoi passare un oggetto con tutto quello extra {message:'stringa',cssClass:'danger',etc:etc}
         * @param header
         * @param inputs
         * @param buttons
         * @param extra
         */
        this.showAlert = (header, inputs, buttons, extra) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create(Object.assign({ header,
                inputs,
                buttons, backdropDismiss: false }, extra));
            yield alert.present();
            return yield alert.onWillDismiss();
        });
        this.reload = (time = 1000) => {
            setTimeout(() => window.location.reload(), time);
        };
        // Lazy load endpoint
        this.initEndpoint().catch(console.error);
    }
    initEndpoint() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //console.debug('initEndpoint...');
            if (this._endpoint) {
                //console.debug('endpoint already initialized', this._endpoint);
                return this._endpoint;
            }
            yield this.storage.ready();
            let res = yield this.storage.get('endpoint');
            if (res) {
                //console.debug('[ENDPOINT] reading from storage', res);
                this._endpoint = res;
                return this._endpoint;
                //} else {
                //console.debug('[ENDPOINT] not in storage');
            }
            return null;
        });
    }
    getEndpoint() {
        //console.debug('getEndpoint()....');
        return this._endpoint;
    }
    setEndpoint(value) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this._endpoint = value;
            //console.debug('[ENDPOINT] writing');
            yield this.storage.set('endpoint', this._endpoint);
        });
    }
    createConfirm(message, header = '', cancel = 'Cancel', confirm = 'OK') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let buttons = [
                {
                    text: confirm,
                    role: 'confirm',
                    cssClass: 'success',
                }
            ];
            let res = yield this.showAlert(header, [], buttons, { message: message, backdropDismiss: false });
            return res && res.role && res.role === 'confirm';
        });
    }
    createDialog(message, header = '', confirm = 'OK') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let buttons = [
                {
                    text: confirm,
                    role: 'confirm',
                    cssClass: 'success',
                }
            ];
            yield this.showAlert(header, [], buttons, { message: message });
        });
    }
    getRouteParam(activatedRouter, paramName, paramDefault = null) {
        let res = activatedRouter.snapshot.paramMap.get(paramName);
        if (res === null) {
            return paramDefault;
        }
        return res;
    }
    endpointRequest(url, options = {}, extra = {}) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let loaderMessage = extra.hasOwnProperty('loader') && extra.loader.hasOwnProperty('message') ? extra.loader.message : null;
            if (loaderMessage) {
                yield this.showLoading(loaderMessage);
            }
            let reqUrl = this.getEndpoint() + url;
            try {
                let res = yield axios(reqUrl, options);
                if (loaderMessage) {
                    yield this.hideLoading();
                }
                if (res && res.hasOwnProperty('data') && res.data.hasOwnProperty('data')) {
                    return res.data.data;
                }
                // noinspection ExceptionCaughtLocallyJS
                throw new Error('Unexplainable error');
            }
            catch (err) {
                if (loaderMessage) {
                    yield this.hideLoading();
                }
                let newError = err;
                if (err.response.hasOwnProperty('data') && err.response.data.hasOwnProperty('errors') && Array.isArray(err.response.data.errors) && err.response.data.errors.length > 0) {
                    newError = err.response.data.errors[0];
                }
                switch (err.response.status) {
                    case 400:
                        throw new BadRequest(newError);
                    case 404:
                        throw new NotFound(newError);
                    default:
                        throw new InternalServerError(newError);
                }
            }
        });
    }
}
