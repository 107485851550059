import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Auth, UserProfile} from './auth.interface';
import {BehaviorSubject} from 'rxjs';
import {UtilsService} from '../common/utils.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private auth: Auth;
    private user: UserProfile;
    private image: string = '';
    private readySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private storage: Storage, private utilsService: UtilsService) {
        this.initializeAuth();
        this.initializeUser();
    }

    public async storeAuth(auth: Auth): Promise<void> {
        await this.storage.set('auth', auth);
        this.auth = auth;
    }

    public async cleanAuth(): Promise<void> {
        await this.storage.remove('auth');
        this.auth = null;
    }

    public resetPasswordByUsername(username: string): void {
        throw new Error('Method to be implemented both on frontend and backend');
    }

    public isLogged(): boolean {
        return typeof this.auth !== 'undefined' && this.auth !== null;
    }

    public async isLoggedForGuards(): Promise<boolean> {
        await this.authReady();
        return typeof this.auth !== 'undefined' && this.auth !== null;
    }

    private async initializeAuth(): Promise<void> {
        await this.storage.ready();
        this.auth = await this.storage.get('auth');
        // Per sicurezza verifica che l'endpoint esista, non è strettamente necessario.
        await this.utilsService.initEndpoint();
        this.readySubject.next(true);
    }

    private async initializeUser(): Promise<void> {
        await this.storage.ready();
        this.user = await this.storage.get('user');
    }

    public async authReady(): Promise<Auth> {

        return new Promise<Auth>((resolve) => {
            this.readySubject.subscribe((response) => {
                if (response === true) {
                    return resolve(this.auth);
                }
            });
        });

    }

    public getAuth(): Auth {
        return this.auth;
    }

    public getUser(): UserProfile {
        return this.user;
    }

    storeUser = async (user: UserProfile) => {
        await this.storage.set('user', user);
        this.user = user;
    };
}
