import * as i0 from "@angular/core";
export class NetworkService {
    constructor() {
        this.online = window.navigator.onLine;
        this.isOnline = () => {
            return this.online;
        };
    }
    initialize() {
        window.addEventListener('online', () => {
            this.online = true;
        });
        window.addEventListener('offline', () => {
            this.online = false;
        });
    }
}
NetworkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NetworkService_Factory() { return new NetworkService(); }, token: NetworkService, providedIn: "root" });
