import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { UtilsService } from '../common/utils.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
import * as i2 from "../common/utils.service";
export class AuthService {
    constructor(storage, utilsService) {
        this.storage = storage;
        this.utilsService = utilsService;
        this.image = '';
        this.readySubject = new BehaviorSubject(false);
        this.storeUser = (user) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.storage.set('user', user);
            this.user = user;
        });
        this.initializeAuth();
        this.initializeUser();
    }
    storeAuth(auth) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.storage.set('auth', auth);
            this.auth = auth;
        });
    }
    cleanAuth() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.storage.remove('auth');
            this.auth = null;
        });
    }
    resetPasswordByUsername(username) {
        throw new Error('Method to be implemented both on frontend and backend');
    }
    isLogged() {
        return typeof this.auth !== 'undefined' && this.auth !== null;
    }
    isLoggedForGuards() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.authReady();
            return typeof this.auth !== 'undefined' && this.auth !== null;
        });
    }
    initializeAuth() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.storage.ready();
            this.auth = yield this.storage.get('auth');
            // Per sicurezza verifica che l'endpoint esista, non è strettamente necessario.
            yield this.utilsService.initEndpoint();
            this.readySubject.next(true);
        });
    }
    initializeUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.storage.ready();
            this.user = yield this.storage.get('user');
        });
    }
    authReady() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => {
                this.readySubject.subscribe((response) => {
                    if (response === true) {
                        return resolve(this.auth);
                    }
                });
            });
        });
    }
    getAuth() {
        return this.auth;
    }
    getUser() {
        return this.user;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Storage), i0.ɵɵinject(i2.UtilsService)); }, token: AuthService, providedIn: "root" });
