import * as tslib_1 from "tslib";
import axios from 'axios';
import { AuthService } from '../auth/auth.service';
import { LoadingController, NavController, ToastController } from '@ionic/angular';
import { UtilsService } from "./utils.service";
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
import * as i2 from "@ionic/angular";
import * as i3 from "./utils.service";
export class InterceptorService {
    constructor(authService, navigator, loader, toast, utilsService) {
        this.authService = authService;
        this.navigator = navigator;
        this.loader = loader;
        this.toast = toast;
        this.utilsService = utilsService;
    }
    initialize() {
        axios.interceptors.request.use((config) => {
            let auth = this.authService.getAuth();
            if (!(config['url'].indexOf('/token') != -1 && config['method'] == 'post') && auth) {
                config['headers']['Authorization'] = 'Bearer ' + auth.token;
            }
            return config;
        }, (error) => {
            // Fai qualcosa nel caso di un errore return Promise.reject(error);
            console.error('[InterceptorService] inizialize error', error);
        });
        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const originalRequest = error.config;
            // console.debug('axios interceptor onRejected', error.response.status, error.response.data );
            // AGX: axios generalmente ritorna 504 quando sei offline
            if (error.response.status === 504) {
                console.warn('axios fallito perché offline');
                return error;
            }
            // Se è 403 oppure è la URL del refresh token allora non possiamo + continuare e deve tornare alla home
            if (originalRequest['url'].indexOf('/token/refresh') !== -1) {
                console.debug('redirecting to home', error.response.status);
                yield this.authService.cleanAuth();
                // Chiude tutti i popup eventualmente attivi
                try {
                    yield this.loader.dismiss();
                    yield this.toast.dismiss();
                }
                catch (err) {
                    // ignora l'errore nel caso il loader o il toast non fosse attivo
                }
                yield this.navigator.navigateRoot('/');
                return error;
            }
            //console.debug('interceptor rejected with', error.response.status);
            if (error.response.status === 401 /*|| error.response.status === 403*/) {
                let auth = this.authService.getAuth();
                if (auth) {
                    console.debug('refreshing token for:', originalRequest.url);
                    let res = yield axios.post(this.utilsService.getEndpoint() + '/token/refresh', {}, {
                        headers: {
                            'Authorization': 'Bearer ' + auth.refresh
                        }
                    });
                    if (!!res && res.status === 200 && res.data.hasOwnProperty('data')) {
                        //console.info('new token',res.data.data);
                        yield this.authService.storeAuth(res.data.data);
                        // TODO: add the new token to the header of the originalRequest
                        auth = this.authService.getAuth();
                        originalRequest.headers['Authorization'] = 'Bearer ' + auth.token;
                        return axios(originalRequest);
                    }
                    console.error('token refresh failed.', res);
                }
            }
            return Promise.reject(error);
        }));
    }
}
InterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InterceptorService_Factory() { return new InterceptorService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.NavController), i0.ɵɵinject(i2.LoadingController), i0.ɵɵinject(i2.ToastController), i0.ɵɵinject(i3.UtilsService)); }, token: InterceptorService, providedIn: "root" });
