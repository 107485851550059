<ion-header>
	<ion-toolbar>
		<ion-title *ngIf="title">{{title}}</ion-title>

		<ion-buttons slot="end">
			<ion-button (click)="doAbort()">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>

	<ion-toolbar>
		<ion-searchbar placeholder="Ricerca (min. {{ this.minChars }} caratteri)" debounce="350" (ionClear)="doReset()" (ionChange)="doChange($event)"></ion-searchbar>
	</ion-toolbar>
</ion-header>

<ion-content>
	<div *ngIf="isBusy" style="display:flex; justify-content: center; align-items: center; height: 100%">
		<ion-spinner name="bubbles" style="transform: scale(2)"></ion-spinner>
	</div>
	<ion-list *ngIf="filteredItems.length > 0 && !isBusy">
		<ion-radio-group>
			<ion-item *ngFor="let item of filteredItems">
				<ion-label>{{item.name}}</ion-label>
				<ion-checkbox #MyCheckbox [checked]="doChecked(item.id)" (click)="clicked(item)" [value]="item.id">{{item.name}}</ion-checkbox>
			</ion-item>
		</ion-radio-group>
	</ion-list>
</ion-content>

<ion-footer>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-button expand="block" color="medium" fill="outline" (click)="doAbort()">Annulla</ion-button>
			</ion-col>
			<ion-col>
				<ion-button expand="block" color="success" (click)="doApply()">Applica</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-footer>
