/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./single-select.component";
var styles_SingleSelectComponent = [".image[_ngcontent-%COMP%] {--border-radius: 5px;width: 100% !important;height: 100% !important;max-width: 150px !important;max-height: 100px !important;}"];
var RenderType_SingleSelectComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SingleSelectComponent, data: {} });
export { RenderType_SingleSelectComponent as RenderType_SingleSelectComponent };
function View_SingleSelectComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "ion-buttons", [["slot", "start"]], null, null, null, i1.View_IonButtons_0, i1.RenderType_IonButtons)), i0.ɵdid(1, 49152, null, 0, i2.IonButtons, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 4, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doSelectAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_IonButton_0, i1.RenderType_IonButton)), i0.ɵdid(3, 49152, null, 0, i2.IonButton, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "ion-icon", [["name", "done-all"], ["slot", "icon-only"]], null, null, null, i1.View_IonIcon_0, i1.RenderType_IonIcon)), i0.ɵdid(5, 49152, null, 0, i2.IonIcon, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { name: [0, "name"] }, null), (_l()(), i0.ɵted(-1, 0, [" Tutti "]))], function (_ck, _v) { var currVal_0 = "done-all"; _ck(_v, 5, 0, currVal_0); }, null); }
function View_SingleSelectComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ion-title", [], null, null, null, i1.View_IonTitle_0, i1.RenderType_IonTitle)), i0.ɵdid(1, 49152, null, 0, i2.IonTitle, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
function View_SingleSelectComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ion-img", [["class", "image"]], null, null, null, i1.View_IonImg_0, i1.RenderType_IonImg)), i0.ɵdid(1, 49152, null, 0, i2.IonImg, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { src: [0, "src"] }, null)], function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.src, ""); _ck(_v, 1, 0, currVal_0); }, null); }
function View_SingleSelectComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "ion-item", [], null, null, null, i1.View_IonItem_0, i1.RenderType_IonItem)), i0.ɵdid(1, 49152, null, 0, i2.IonItem, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 4, "ion-radio", [], null, [[null, "click"], [null, "ionBlur"], [null, "ionSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionSelect" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5)._handleIonSelect($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.doChecked(_v.context.$implicit.id) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_IonRadio_0, i1.RenderType_IonRadio)), i0.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.RadioValueAccessor]), i0.ɵdid(4, 49152, [[1, 4], ["MyCheckbox", 4]], 0, i2.IonRadio, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { value: [0, "value"] }, null), i0.ɵdid(5, 16384, null, 0, i2.RadioValueAccessor, [i0.ElementRef], null, null), (_l()(), i0.ɵted(6, 0, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, 0, 2, "ion-label", [["text-wrap", ""]], null, null, null, i1.View_IonLabel_0, i1.RenderType_IonLabel)), i0.ɵdid(8, 49152, null, 0, i2.IonLabel, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵted(9, 0, ["\u00A0", ""])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_SingleSelectComponent_4)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 4, 0, currVal_0); var currVal_3 = _v.context.$implicit.src; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.text; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.text; _ck(_v, 9, 0, currVal_2); }); }
export function View_SingleSelectComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { checkboxes: 1 }), (_l()(), i0.ɵeld(1, 0, null, null, 19, "ion-header", [], null, null, null, i1.View_IonHeader_0, i1.RenderType_IonHeader)), i0.ɵdid(2, 49152, null, 0, i2.IonHeader, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(3, 0, null, 0, 11, "ion-toolbar", [], null, null, null, i1.View_IonToolbar_0, i1.RenderType_IonToolbar)), i0.ɵdid(4, 49152, null, 0, i2.IonToolbar, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_SingleSelectComponent_1)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_SingleSelectComponent_2)), i0.ɵdid(8, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, 0, 5, "ion-buttons", [["slot", "end"]], null, null, null, i1.View_IonButtons_0, i1.RenderType_IonButtons)), i0.ɵdid(10, 49152, null, 0, i2.IonButtons, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(11, 0, null, 0, 3, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAbort() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_IonButton_0, i1.RenderType_IonButton)), i0.ɵdid(12, 49152, null, 0, i2.IonButton, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(13, 0, null, 0, 1, "ion-icon", [["name", "close"], ["slot", "icon-only"]], null, null, null, i1.View_IonIcon_0, i1.RenderType_IonIcon)), i0.ɵdid(14, 49152, null, 0, i2.IonIcon, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { name: [0, "name"] }, null), (_l()(), i0.ɵeld(15, 0, null, 0, 5, "ion-toolbar", [], null, null, null, i1.View_IonToolbar_0, i1.RenderType_IonToolbar)), i0.ɵdid(16, 49152, null, 0, i2.IonToolbar, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(17, 0, null, 0, 3, "ion-searchbar", [["debounce", "250"], ["placeholder", "Ricerca..."]], null, [[null, "ionClear"], [null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i0.ɵnov(_v, 20)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i0.ɵnov(_v, 20)._handleInputEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionClear" === en)) {
        var pd_2 = (_co.doReset() !== false);
        ad = (pd_2 && ad);
    } if (("ionChange" === en)) {
        var pd_3 = (_co.doChange($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_IonSearchbar_0, i1.RenderType_IonSearchbar)), i0.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.TextValueAccessor]), i0.ɵdid(19, 49152, null, 0, i2.IonSearchbar, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { debounce: [0, "debounce"], placeholder: [1, "placeholder"] }, null), i0.ɵdid(20, 16384, null, 0, i2.TextValueAccessor, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(21, 0, null, null, 9, "ion-content", [], null, null, null, i1.View_IonContent_0, i1.RenderType_IonContent)), i0.ɵdid(22, 49152, null, 0, i2.IonContent, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(23, 0, null, 0, 7, "ion-list", [], null, null, null, i1.View_IonList_0, i1.RenderType_IonList)), i0.ɵdid(24, 49152, null, 0, i2.IonList, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(25, 0, null, 0, 5, "ion-radio-group", [], null, [[null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; if (("ionBlur" === en)) {
        var pd_0 = (i0.ɵnov(_v, 28)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i0.ɵnov(_v, 28)._handleChangeEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_IonRadioGroup_0, i1.RenderType_IonRadioGroup)), i0.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectValueAccessor]), i0.ɵdid(27, 49152, null, 0, i2.IonRadioGroup, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { value: [0, "value"] }, null), i0.ɵdid(28, 16384, null, 0, i2.SelectValueAccessor, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_SingleSelectComponent_3)), i0.ɵdid(30, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(31, 0, null, null, 15, "ion-footer", [], null, null, null, i1.View_IonFooter_0, i1.RenderType_IonFooter)), i0.ɵdid(32, 49152, null, 0, i2.IonFooter, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(33, 0, null, 0, 13, "ion-grid", [], null, null, null, i1.View_IonGrid_0, i1.RenderType_IonGrid)), i0.ɵdid(34, 49152, null, 0, i2.IonGrid, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(35, 0, null, 0, 11, "ion-row", [], null, null, null, i1.View_IonRow_0, i1.RenderType_IonRow)), i0.ɵdid(36, 49152, null, 0, i2.IonRow, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(37, 0, null, 0, 4, "ion-col", [], null, null, null, i1.View_IonCol_0, i1.RenderType_IonCol)), i0.ɵdid(38, 49152, null, 0, i2.IonCol, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(39, 0, null, 0, 2, "ion-button", [["color", "medium"], ["expand", "block"], ["fill", "outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAbort() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_IonButton_0, i1.RenderType_IonButton)), i0.ɵdid(40, 49152, null, 0, i2.IonButton, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { color: [0, "color"], expand: [1, "expand"], fill: [2, "fill"] }, null), (_l()(), i0.ɵted(-1, 0, ["Annulla"])), (_l()(), i0.ɵeld(42, 0, null, 0, 4, "ion-col", [], null, null, null, i1.View_IonCol_0, i1.RenderType_IonCol)), i0.ɵdid(43, 49152, null, 0, i2.IonCol, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(44, 0, null, 0, 2, "ion-button", [["color", "success"], ["expand", "block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doApply() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_IonButton_0, i1.RenderType_IonButton)), i0.ɵdid(45, 49152, null, 0, i2.IonButton, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { color: [0, "color"], disabled: [1, "disabled"], expand: [2, "expand"] }, null), (_l()(), i0.ɵted(-1, 0, ["Applica"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canSelectAll; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 8, 0, currVal_1); var currVal_2 = "close"; _ck(_v, 14, 0, currVal_2); var currVal_3 = "250"; var currVal_4 = "Ricerca..."; _ck(_v, 19, 0, currVal_3, currVal_4); var currVal_5 = _co.selected; _ck(_v, 27, 0, currVal_5); var currVal_6 = _co.filteredItems; _ck(_v, 30, 0, currVal_6); var currVal_7 = "medium"; var currVal_8 = "block"; var currVal_9 = "outline"; _ck(_v, 40, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = "success"; var currVal_11 = !_co.hasSomeChecked(); var currVal_12 = "block"; _ck(_v, 45, 0, currVal_10, currVal_11, currVal_12); }, null); }
export function View_SingleSelectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pg-multiselect-dialog", [], null, null, null, View_SingleSelectComponent_0, RenderType_SingleSelectComponent)), i0.ɵdid(1, 49152, null, 0, i5.SingleSelectComponent, [i2.ModalController], null, null)], null, null); }
var SingleSelectComponentNgFactory = i0.ɵccf("pg-multiselect-dialog", i5.SingleSelectComponent, View_SingleSelectComponent_Host_0, { title: "title", items: "items", selected: "selected", canSelectAll: "canSelectAll" }, {}, []);
export { SingleSelectComponentNgFactory as SingleSelectComponentNgFactory };
