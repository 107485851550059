import * as tslib_1 from "tslib";
import axios from 'axios';
import { BadRequest, Conflict, InternalServerError, NotFound, TooManyRequests } from 'ts-httpexceptions';
import { AuthService } from './auth.service';
import { UtilsService } from "../common/utils.service";
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "../common/utils.service";
export class RepositoryService {
    constructor(authService, utilsService) {
        this.authService = authService;
        this.utilsService = utilsService;
    }
    getAuthByCredentials(username, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let res = null;
            let endpoint = this.utilsService.getEndpoint() + '/token';
            try {
                res = yield axios.post(endpoint, { username: username, password: password });
            }
            catch (err) {
                switch (err.response.status) {
                    case 400:
                        throw new BadRequest(err);
                    case 404:
                        throw new NotFound(err);
                    case 429:
                        throw new TooManyRequests(err.response.data.data.minutesToWait);
                    default:
                        throw new InternalServerError(err);
                }
            }
            if (!!res && res.status === 200) {
                return res.data.data;
            }
            throw new Error('Unexplainable error');
        });
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let res = null;
            let endpoint = this.utilsService.getEndpoint() + '/token';
            try {
                res = yield axios.delete(endpoint);
            }
            catch (err) {
                switch (err.response.status) {
                    case 404:
                        throw new NotFound(err);
                    case 409:
                        throw new Conflict(err);
                    default:
                        throw new InternalServerError(err);
                }
            }
            if (!!res && res.status === 204) {
                return true;
            }
            throw new Error('Unexplainable error');
        });
    }
    getUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let res = null;
            let endpoint = this.utilsService.getEndpoint() + '/me';
            try {
                res = yield axios(endpoint);
            }
            catch (err) {
                switch (err.response.status) {
                    case 400:
                        throw new BadRequest(err);
                    default:
                        throw new InternalServerError(err);
                }
            }
            if (!!res && res.status === 200) {
                return res.data.data.user;
            }
            throw new Error('Unexplainable error');
        });
    }
}
RepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RepositoryService_Factory() { return new RepositoryService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.UtilsService)); }, token: RepositoryService, providedIn: "root" });
