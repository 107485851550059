import * as tslib_1 from "tslib";
import { QueryList } from '@angular/core';
import { ModalController } from '@ionic/angular';
import axios from "axios";
import { UtilsService } from "../utils.service";
export class MultiSelectAjaxComponent {
    constructor(modalController, utilsService) {
        this.modalController = modalController;
        this.utilsService = utilsService;
        this.name = null;
        this.title = null;
        this.endpoint = null;
        this.selected = [];
        this.minChars = 3;
        this.filteredItems = [];
        this.sSelected = [];
        this.label = 'Sel.';
        this.isBusy = false;
        this.clicked = (item) => {
            let cb = () => {
                if (this.doChecked(item.id)) {
                    let i = -1;
                    this.sSelected.map((v, k) => {
                        if (v['id'] == item.id) {
                            i = k;
                        }
                    });
                    if (i != -1) {
                        this.sSelected.splice(i, 1);
                    }
                }
                else {
                    this.sSelected.push({ id: item.id, name: item.name });
                }
            };
            setTimeout(() => { return cb(); }, 100);
        };
    }
    ionViewDidEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.doReset();
            Object.assign(this.sSelected, this.selected);
        });
    }
    loadItems(search = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const endpoint = this.utilsService.getEndpoint() + this.endpoint;
            if (!this.isBusy) {
                this.isBusy = true;
                yield axios.post(endpoint, { search: search }, { timeout: 5000 })
                    .then(response => {
                    this.filteredItems = response.data.data[this.name];
                    this.isBusy = false;
                })
                    .catch(error => {
                    let msg = (error.response) ?
                        error.response.data.errors[Object.keys(error.response.data.errors)[0]].search : 'La ricerca sta impiegando troppo tempo, inserisci più caratteri.';
                    this.utilsService.showToast(msg, { color: "danger" });
                    this.isBusy = false;
                });
            }
        });
    }
    doReset() {
        this.filteredItems = [];
    }
    doChecked(id) {
        return this.sSelected.some(e => e['id'] == id);
    }
    doChange($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let str = $event.target.value.toLowerCase();
            if (str.length >= this.minChars)
                yield this.loadItems(str);
        });
    }
    doAbort() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.modalController.dismiss(this.selected, 'abort');
        });
    }
    doApply() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let cb = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (this.sSelected.length === 0) {
                    yield this.modalController.dismiss([], 'all');
                    return;
                }
                yield this.modalController.dismiss(this.sSelected, 'confirm');
            });
            setTimeout(() => { return cb(); }, 100);
        });
    }
}
