import {Component, OnInit} from '@angular/core';

import {InterceptorService} from './common/interceptor.service';
import {NetworkService} from './common/network.service';
import {deferredAddToHomePrompt, restartBrowserApp, PgServiceWorkerService} from "./common/pg-service-worker.service";
import {MenuController, NavController, Platform} from "@ionic/angular";
import {AuthService} from "./auth/auth.service";
import {RepositoryService} from "./auth/repository.service";
import {UtilsService} from "./common/utils.service";
import {version} from '../../package.json';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    public selectedIndex = 0;
    public subSelectedIndex = 0;
    public subs = {interviews: false};
    public appPages = [
        {title: 'Home', url: '/home/', icon: 'home'},
        {
            title: 'Prodotti',
            url: '',
            icon: 'cube',
            subKey: 'products',
            icon2: 'chevron-down',
            subMenu: [
                {title: 'Catalogo', url: '/products-catalogue/', icon: 'list'},
                {title: 'Gadgets', url: '/gadgets/', icon: 'gift'}
            ]
        },
        {
            title: 'Alberatura Cluster',
            url: '',
            icon: 'git-branch',
            subKey: 'mastings-cluster',
            icon2: 'chevron-down',
            subMenu: [
                {title: 'Lista', url: '/mastings-cluster/', icon : 'list'},
                {title: 'Landing Pages', url: '/mastings-cluster/lp', icon: 'images'}
            ]
        },
        {title: 'Progetti', url: '/projects/', icon: 'copy'},
        {
            title: 'Interviste',
            url: '',
            icon: 'list',
            subKey: 'interviews',
            icon2: 'chevron-down',
            subMenu: [
                {title: 'Lista', url: '/interviews/list/', icon: 'list'},
                {title: 'Anomalie Device', url: '/interviews/anomalous-device-codes-list/', icon: 'help'},
                {title: 'Sondaggi', url: '/surveys/', icon: 'clipboard'},
                {title: 'Prodotti Neostick', url: '/additional-products/', icon: 'cube'},
                {title: 'Promo', url: '/promos/', icon: 'megaphone'},
                {title: 'Importazione', url: '/interviews/import', icon: 'cloud-upload'},
                {title: 'Analisi Venduto', url: '/interviews/sellout-analysis', icon: 'analytics'},
                {title: 'Generazione Inserimenti', url: '/interviews/generate-import', icon: 'cloud-download'},
                {title: 'Configurazione Progetti', url: '/interviews/projects-configuration', icon: 'color-filter'}

            ]
        },
        {
            title: 'Gestione Accessori',
            url: '',
            icon: 'basket',
            subKey: 'accessories',
            icon2: 'chevron-down',
            subMenu: [
                {title: 'Lista Accessori', url: '/accessories/', icon: 'list'},
                {title: 'Tipologie', url: '/accessory-typologies/', icon: 'bookmark'},
                {title: 'Categorie', url: '/accessory-categories/', icon: 'albums'},
            ]
        },
        {title: 'Logout', url: '/logout/', icon: 'log-out'}
    ];
  public version: string = '';

    constructor(
        private platform: Platform,
        public authService: AuthService,
        private authRepository: RepositoryService,
        private navigator: NavController,
        private interceptorService: InterceptorService,
        private network: NetworkService,
        private sw: PgServiceWorkerService,
        private util: UtilsService,
        private menu: MenuController
    ) {
        this.version = version;
        this.initializeApp();
    }

    initializeApp() {
        /*this.platform.ready().then(() => {
          this.statusBar.styleDefault();
          this.splashScreen.hide();
        });*/

        this.network.initialize();
        this.interceptorService.initialize();
        this.sw.init();
    }

    ngOnInit() {

    }

    public async logout(): Promise<void> {

        try {

            await this.authRepository.logout();
            await this.authService.cleanAuth();
            await this.navigator.navigateRoot('login');

        } catch (e) {
            await this.authService.cleanAuth();
            await this.navigator.navigateRoot('login');
        }

        window.location.href = '/';
    }

    public click = async (url: string, i: any, type: string, submenu: [] = [], key: string = '') => {
        this.selectedIndex = i;
        if (submenu.length == 0) {
            await this.menu.toggle();
            if (type == 'menu') {
                this.subSelectedIndex = -1;
                for (let s of Object.keys(this.subs)) {
                    this.subs[s] = false;
                }
                if (url == '/logout/') {
                    await this.logout();
                }
            } else {
                this.subSelectedIndex = i;
            }
        } else {
            this.subs[key] = !this.subs[key];
        }
    }


    public doInstallPwa() {
        console.debug('doInstallPwa()');
        // noinspection TypeScriptValidateJSTypes
        deferredAddToHomePrompt.prompt();
    }


    public doCheckUpdates() {
        this.util.showToast('Verifica disponibilità di aggiornamenti in background...\nL\'operazione potrebbe richiedere alcuni minuti.',{color:'success',duration:2000})
            .catch(console.error);
        this.sw.checkForUpdate();
    }


    public doRefreshBrowser() {
        restartBrowserApp();
    }

}
