import {Component, Input, QueryList, ViewChildren} from '@angular/core';
import {IonCheckbox, ModalController} from '@ionic/angular';

export interface IMultiSelectComponentItem {
    id: string;
    name: string;
}

@Component({
    selector: 'pg-multiselect-dialog',
    templateUrl: './multi-select.component.html',
    //styleUrls: ['./menu.component.scss']
})

export class MultiSelectComponent  {

    @Input() public title: string = null;
    @Input() private items: Array<IMultiSelectComponentItem>;
    @Input() private selected: any = [];
    @Input() public canSelectAll: boolean = false;

    @ViewChildren('MyCheckbox') private checkboxes: QueryList<IonCheckbox>;
    public filteredItems: Array<IMultiSelectComponentItem> = [];
    public sSelected: any = [];
    public label = 'Sel.';

    constructor(private modalController: ModalController) {
    }

    ionViewDidEnter() {
        this.doReset();
        Object.assign(this.sSelected, this.selected);
    }

    public doReset() {
        this.filteredItems = this.items;
    }

    public clicked = (id) => {
        let cb = () => {
            if (this.doChecked(id)) {
                let i = -1;
                this.sSelected.map((v, k) => {
                    if (v['id'] == id) {
                        i = k;
                    }
                });
                if (i != -1) {
                    this.sSelected.splice(i, 1);
                }
            } else {
                this.sSelected.push({id: id});
            }
        }
        setTimeout(() => { return cb();}, 100);
    };

    doChecked(id: string) {
        return this.sSelected.some(e=>e['id'] == id);
    }

    doChange($event) {
        let s = $event.target.value.toLowerCase();
        this.filteredItems = this.items.filter(v => v.name.toLowerCase().indexOf(s) >= 0 );
    }

    async doAbort() {
        await this.modalController.dismiss(this.selected,'abort');
    }

    async doApply() {
        this.doReset();
        let cb = async () => {
            let res: Array<{}> = this.checkboxes.filter(v => v.checked).map(v => {
                return {id: v.value}
            });
            if (res.length === 0) {
                await this.modalController.dismiss([], 'all');
                return;
            }
            await this.modalController.dismiss(res, 'confirm');
        }
        setTimeout(() => { return cb(); }, 100);
    }

    async doSelectAll() {
        if (this.label == 'Sel.') {
            this.label = 'Tutti';
            this.filteredItems = [];
            let temp = this.checkboxes.filter(v => v.checked).map(v => {
                return {id: v.value}
            });
            temp.forEach((s, sk) => {
                this.items.forEach((v, vk) => {
                    if (v['id'] == s['id']) {
                        this.filteredItems.push(v);
                    }
                });
            });
        } else {
            this.label = 'Sel.';
            this.doReset();
        }
    }

}
