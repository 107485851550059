import * as tslib_1 from "tslib";
import { QueryList } from '@angular/core';
import { ModalController } from '@ionic/angular';
export class SingleSelectComponent {
    constructor(modalController) {
        this.modalController = modalController;
        this.title = null;
        this.canSelectAll = false;
        this.filteredItems = [];
        this.hasSomeChecked = () => {
            return this.selected != '';
        };
    }
    ionViewDidEnter() {
        this.doReset();
    }
    doReset() {
        this.filteredItems = this.items;
    }
    doChecked(id) {
        this.selected = id;
    }
    doChange($event) {
        let s = $event.target.value.toLowerCase();
        console.log(s, this.items);
        this.filteredItems = this.items.filter(v => {
            if (v.text != null && v.text.length > 0) {
                return v.text.toLowerCase().indexOf(s) >= 0;
            }
        });
    }
    doAbort() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.modalController.dismiss(null, 'abort');
        });
    }
    doApply() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let res = this.selected;
            yield this.modalController.dismiss(res, 'confirm');
        });
    }
    doSelectAll() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.modalController.dismiss(null, 'all');
        });
    }
}
