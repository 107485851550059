import * as tslib_1 from "tslib";
import { QueryList } from '@angular/core';
import { ModalController } from '@ionic/angular';
export class MultiSelectComponent {
    constructor(modalController) {
        this.modalController = modalController;
        this.title = null;
        this.selected = [];
        this.canSelectAll = false;
        this.filteredItems = [];
        this.sSelected = [];
        this.label = 'Sel.';
        this.clicked = (id) => {
            let cb = () => {
                if (this.doChecked(id)) {
                    let i = -1;
                    this.sSelected.map((v, k) => {
                        if (v['id'] == id) {
                            i = k;
                        }
                    });
                    if (i != -1) {
                        this.sSelected.splice(i, 1);
                    }
                }
                else {
                    this.sSelected.push({ id: id });
                }
            };
            setTimeout(() => { return cb(); }, 100);
        };
    }
    ionViewDidEnter() {
        this.doReset();
        Object.assign(this.sSelected, this.selected);
    }
    doReset() {
        this.filteredItems = this.items;
    }
    doChecked(id) {
        return this.sSelected.some(e => e['id'] == id);
    }
    doChange($event) {
        let s = $event.target.value.toLowerCase();
        this.filteredItems = this.items.filter(v => v.name.toLowerCase().indexOf(s) >= 0);
    }
    doAbort() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.modalController.dismiss(this.selected, 'abort');
        });
    }
    doApply() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.doReset();
            let cb = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let res = this.checkboxes.filter(v => v.checked).map(v => {
                    return { id: v.value };
                });
                if (res.length === 0) {
                    yield this.modalController.dismiss([], 'all');
                    return;
                }
                yield this.modalController.dismiss(res, 'confirm');
            });
            setTimeout(() => { return cb(); }, 100);
        });
    }
    doSelectAll() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.label == 'Sel.') {
                this.label = 'Tutti';
                this.filteredItems = [];
                let temp = this.checkboxes.filter(v => v.checked).map(v => {
                    return { id: v.value };
                });
                temp.forEach((s, sk) => {
                    this.items.forEach((v, vk) => {
                        if (v['id'] == s['id']) {
                            this.filteredItems.push(v);
                        }
                    });
                });
            }
            else {
                this.label = 'Sel.';
                this.doReset();
            }
        });
    }
}
