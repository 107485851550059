<ion-app>
    <ion-split-pane contentId="main-content">
        <ion-menu menu-id="menu" *ngIf="authService.isLogged()" contentId="main-content" type="overlay">
            <ion-header>
                <ion-toolbar>
                    <ion-item>Menu</ion-item>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ion-list id="inbox-list">
                    <ion-row>
                        <ion-col size="4" offset="4">
                            <img src="assets/img/BAT_logo_for_menu.png">
                        </ion-col>
                    </ion-row>
                    <ion-row style="justify-content: center;">
                        <h4>{{authService.getUser()?.name}}</h4>
                    </ion-row>
                    <ion-row style="justify-content: center;">
                        <h6>{{authService.getUser()?.email}}</h6>
                    </ion-row>
                    <ion-item-divider></ion-item-divider>
                    <ion-item-group *ngFor="let p of appPages; let i = index">
                        <ion-item *ngIf="p.url != ''" (click)="click(p.url, i, 'menu')" routerDirection="root" [routerLink]="[p?.url]" lines="none"
                                  detail="false" [class.selected]="selectedIndex == i">
                            <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                            <ion-label>{{ p.title }}</ion-label>
                        </ion-item>
                        <ion-item *ngIf="p.url == ''" (click)="click(p.url, i, 'menu', p.subMenu, p.subKey)" lines="none"
                                  detail="false" [class.selected]="selectedIndex == i">
                            <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                            <ion-icon slot="end" [ios]="p.icon2 + '-outline'" [md]="p.icon2 + '-sharp'"></ion-icon>
                            <ion-label>{{ p.title }}</ion-label>
                        </ion-item>
                        <ion-item-group *ngFor="let sub of p?.subMenu; let j = index" submenu [class.visible]="subs[p.subKey]">
                            <ion-item (click)="click(sub.url, j + sub.url, 'submenu')" routerDirection="root" [routerLink]="[sub.url]"
                                      detail="false" [class.selected]="subSelectedIndex == j + sub.url">
                                <ion-icon slot="start" [ios]="sub.icon + '-outline'" [md]="sub.icon + '-sharp'"></ion-icon>
                                <ion-label>{{ sub.title }}</ion-label>
                            </ion-item>
                        </ion-item-group>
                    </ion-item-group>
                </ion-list>
            </ion-content>
            <ion-footer>
                <ion-toolbar>
                    <div class="text-center">v {{version}}</div>
                </ion-toolbar>
            </ion-footer>
        </ion-menu>

        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>
