import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/storage";
export class LoadingBannerService {
    constructor(storage) {
        this.storage = storage;
        this.minutes = 10;
        this.initialize = (force = false) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let res = yield this.storage.get('installinformation');
            let now = moment().format();
            let alert = false;
            if (!res || force) {
                if (force) {
                    now = moment().subtract(this.minutes + 1, 'minutes').format();
                    alert = true;
                }
                yield this.storage.set('installinformation', { installationDate: now, alertShown: alert, type: 'installation' });
            }
        });
        this.update = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let now = moment().format();
            yield this.storage.set('installinformation', { installationDate: now, alertShown: false, type: 'update' });
        });
        this.bannerIsVisibleInPage = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let res = yield this.storage.get('installinformation');
            if (res) {
                let time = res.installationDate;
                let timeMinutes = moment(time).add(this.minutes, 'minutes').format();
                if (!moment().isAfter(timeMinutes)) {
                    return [true, moment(timeMinutes).diff(moment())];
                }
            }
            return [false, 0];
        });
        this.showAlert = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let res = yield this.storage.get('installinformation');
            if (res) {
                yield this.storage.set('installinformation', { installationDate: res.installationDate, alertShown: true, type: res.type });
                return !res.alertShown;
            }
            return false;
        });
    }
}
LoadingBannerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingBannerService_Factory() { return new LoadingBannerService(i0.ɵɵinject(i1.Storage)); }, token: LoadingBannerService, providedIn: "root" });
