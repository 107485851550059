/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./actions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./actions.component";
var styles_ActionsComponent = [i0.styles];
var RenderType_ActionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActionsComponent, data: {} });
export { RenderType_ActionsComponent as RenderType_ActionsComponent };
function View_ActionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ion-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeAction(_v.context.$implicit.action) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-icon", [["slot", "start"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-label", [["text-wrap", ""]], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(5, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(6, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.icon; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_1); }); }
export function View_ActionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsComponent_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actions; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ActionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-actions", [], null, null, null, View_ActionsComponent_0, RenderType_ActionsComponent)), i1.ɵdid(1, 114688, null, 0, i5.ActionsComponent, [i3.PopoverController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActionsComponentNgFactory = i1.ɵccf("app-actions", i5.ActionsComponent, View_ActionsComponent_Host_0, { actions: "actions" }, {}, []);
export { ActionsComponentNgFactory as ActionsComponentNgFactory };
