import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../auth/auth.service";
/**
 * Importare sempre authService se la pagina su cui atterrera' fara' delle chiamate http
 */
export class MustNotBeLoggedGuard {
    constructor(router, authService) {
        this.router = router;
        this.authService = authService;
    }
    canActivate() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let isLogged = yield this.authService.isLoggedForGuards();
            if (isLogged) {
                yield this.router.navigateByUrl('/home');
                return false;
            }
            return true;
        });
    }
}
MustNotBeLoggedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MustNotBeLoggedGuard_Factory() { return new MustNotBeLoggedGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService)); }, token: MustNotBeLoggedGuard, providedIn: "root" });
