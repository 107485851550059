import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PopoverController } from "@ionic/angular";
export class ActionsComponent {
    constructor(popoverController) {
        this.popoverController = popoverController;
        this.closeAction = (action) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.popoverController.dismiss(action);
        });
    }
    ngOnInit() {
    }
}
