import {Injectable} from '@angular/core';
import {Auth, UserProfile} from './auth.interface';
import axios from 'axios';
import {BadRequest, Conflict, InternalServerError, NotFound, TooManyRequests} from 'ts-httpexceptions';
import {AuthService} from './auth.service';
import {UtilsService} from "../common/utils.service";


@Injectable({
    providedIn: 'root'
})
export class RepositoryService {

    constructor(private authService: AuthService, private utilsService: UtilsService) {}

    public async getAuthByCredentials(username: string, password: string): Promise<Auth> {

        let res = null;
        let endpoint = this.utilsService.getEndpoint() + '/token';
        try {
			res = await axios.post(endpoint, {username: username, password: password});
        } catch (err) {

            switch (err.response.status) {
                case 400:
                    throw new BadRequest(err);
                case 404:
                    throw new NotFound(err);
                case 429:
                    throw new TooManyRequests(err.response.data.data.minutesToWait);
                default:
                    throw new InternalServerError(err);
            }
        }

        if (!!res && res.status === 200) {
            return res.data.data;
        }

        throw new Error('Unexplainable error');
    }

    public async logout(): Promise<boolean> {

        let res = null;
        let endpoint = this.utilsService.getEndpoint() + '/token';

        try {
            res = await axios.delete(endpoint);
        } catch (err) {

            switch (err.response.status) {
                case 404:
                    throw new NotFound(err);
                case 409:
                    throw new Conflict(err);
                default:
                    throw new InternalServerError(err);
            }
        }

        if (!!res && res.status === 204) {
            return true;
        }

        throw new Error('Unexplainable error');
    }

    public async getUser(): Promise<UserProfile> {
        let res = null;
        let endpoint = this.utilsService.getEndpoint() + '/me';

        try {
            res = await axios(endpoint);
        } catch (err) {

            switch (err.response.status) {
                case 400:
                    throw new BadRequest(err);
                default:
                    throw new InternalServerError(err);
            }
        }

        if (!!res && res.status === 200) {
            return res.data.data.user;
        }

        throw new Error('Unexplainable error');
    }

}
