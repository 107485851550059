import {Component, Input, OnInit} from '@angular/core';
import {RepositoryService} from "../../surveys/repository.service";
import {UtilsService} from "../utils.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Comuni, Projects, Province, Regioni, Surveys} from "../../surveys/surveys.interface";
import {ModalController} from "@ionic/angular";

@Component({
    selector: 'app-regions',
    templateUrl: './regions.component.html',
    styleUrls: ['./regions.component.scss'],
})

export class RegionsComponent implements OnInit {
    public regions: Regioni[] = [];
    public provinces: Province[] = [];
    public cities: Comuni[] = [];
    public form: FormGroup;

    constructor(private surveysRepository: RepositoryService,
                private utilsService: UtilsService,
                private fb: FormBuilder,
                private modalController: ModalController) {
        this.form = this.fb.group({
            regions: [''],
            provinces: [''],
            cities: [''],
        });
    }

    async ngOnInit() {

        await this.utilsService.showLoading("Caricamento Filtri");
        await this.getRegioni();
        await this.utilsService.hideLoading();
    }


    public getRegioni = async () => {
        try {
            this.regions = await this.surveysRepository.getRegioni();
        } catch (err) {
            await this.utilsService.showToast("Errore durante il recupero delle regioni", {color: "warning"});
        }
    };

    public getProvinceByRegione = async () => {
        this.provinces = [];
        this.cities = [];
        await this.form.patchValue({provinces: [''], cities: ['']});
        let regions = this.form.value.regions;
        try {
            if (regions != '' && regions.length > 0 && regions[0] != '') {
                regions = [regions];
                this.provinces = await this.surveysRepository.getProvinceByRegione(regions);
                console.log(regions);
            }
        } catch (err) {
            await this.utilsService.showToast("Errore durante il recupero delle province", {color: "warning"});
        }
    };

    public getComuniByProvincia = async () => {
        this.cities = [];
        await this.form.patchValue({cities: ['']});
        let provinces = this.form.value.provinces;
        try {
            if (provinces != '' && provinces.length > 0 && provinces[0] != '') {
                provinces = [provinces];
                this.cities = await this.surveysRepository.getComuniByProvincia(provinces);
                console.log(provinces);
            }
        } catch (err) {
            await this.utilsService.showToast("Errore durante il recupero dei comuni", {color: "warning"});
        }
    };

    async doAbort() {
        await this.modalController.dismiss(null,'abort');
    };

    async doApply() {
        let res = this.form.getRawValue();
        console.log(res);
        let regioni = 'Tutte';
        res.regions = [res.regions];
        if (res.regions != '' && res.regions.length > 0 && res.regions[0] != "") {
            regioni = '';
            this.regions.forEach((v, k) => {
                res.regions.forEach((rv, rk) => {
                    if (v['id'] == rv) {
                        regioni += v['name'] + ', ';
                    }
                });
                /*if (v['id'] == res.regions) {
                    regioni += v['name'] + ', ';
                }*/
            });
        }

        let province = 'Tutte';
        res.provinces = [res.provinces];
        if (res.provinces != '' && res.provinces.length > 0 && res.provinces[0] != "") {
            province = '';
            this.provinces.forEach((v, k) => {
                res.provinces.forEach((rv, rk) => {
                    if (v['id'] == rv) {
                        province += v['name'] + ', ';
                    }
                });
                /*if (v['id'] == res.provinces) {
                    province += v['name'] + ', ';
                }*/
            });
        }

        let comuni = 'Tutti';
        res.cities = [res.cities];
        if (res.cities != '' && res.cities.length > 0 && res.cities[0] != "") {
            comuni = '';
            this.cities.forEach((v, k) => {
                res.cities.forEach((rv, rk) => {
                    if (v['id'] == rv) {
                        comuni += v['name'] + ', ';
                    }
                });
                /*if (v['id'] == res.cities) {
                    comuni += v['name'] + ', ';
                }*/
            });
        }

        await this.modalController.dismiss({regions: regioni.replace(/,\s*$/, ""), provinces: province.replace(/,\s*$/, ""), cities: comuni.replace(/,\s*$/, ""), res: res},'confirm');
    }
}
