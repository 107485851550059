import * as tslib_1 from "tslib";
import { SwUpdate } from '@angular/service-worker';
import { NgZone } from '@angular/core';
import { UtilsService } from "./utils.service";
import { interval } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "./utils.service";
export class PgServiceWorkerService {
    constructor(sw, util, zone) {
        this.sw = sw;
        this.util = util;
        this.zone = zone;
        this.swManaged = false;
    }
    init(seconds = 3600) {
        if (this.swManaged) {
            console.error('[SW] already managed');
            return true;
        }
        if (!this.sw.isEnabled) {
            console.warn('[SW] not enabled');
            return false;
        }
        console.info('[SW] ' + (isInStandaloneMode() ? 'STANDALONE' : 'WEB') + ' APP');
        // Lock
        this.swManaged = true;
        //Home Screen
        window.addEventListener('beforeinstallprompt', (event) => {
            console.info('[SW] EVENT beforeinstallprompt');
            deferredAddToHomePrompt = event;
        });
        // Subscribe
        this.sw.available.subscribe((event) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.info('[SW] new version available');
            console.debug('current', event.current);
            console.debug('available', event.available);
            const res = yield this.util.createConfirm('È disponibile una nuova versione.', 'Aggiornamento disponibile', 'Non ora', 'Aggiorna');
            if (res) {
                console.debug('[SW] attivazione');
                this.sw.activateUpdate()
                    .then(() => {
                    console.info('[SW] activateUpdate success');
                })
                    .catch(err => {
                    console.error('[SW] activateUpdate ERROR', err);
                });
            }
        }), err => {
            console.error('available', err);
        });
        this.sw.activated.subscribe((event) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.info('[SW] new version activated:');
            console.debug('current', event.previous);
            console.debug('available', event.current);
            const res = yield this.util.createConfirm('L\'aggiornamento è stato scaricato con successo.<br>' +
                'È necessario riavviare l\'App per rendere effettive le modifiche.<br>' +
                '<b>I dati non salvati andranno persi.</b>', 'Aggiornamento completato', 'Rimanda', 'Riavvia ora');
            if (res) {
                console.info('[SW] reloading the app ...');
                document.location.reload(true);
            }
            else {
                yield this.util.createDialog('Potrai riavviare l\'App toccando “Riavvia App” dal menù.', 'Aggiornamento rimandato');
            }
        }), err => {
            console.error('activated', err);
        });
        // Set timer
        this.zone.runOutsideAngular(() => {
            interval(seconds * 1000).subscribe(() => {
                this.zone.run(() => {
                    this.checkForUpdate();
                });
            });
        });
    }
    checkForUpdate() {
        console.debug('[SW] checkForUpdate()');
        this.sw.checkForUpdate()
            .then(() => {
            console.debug('[SW] checkForUpdate done');
        })
            .catch((err) => {
            console.error('[SW] checkForUpdate error', err);
        });
    }
}
PgServiceWorkerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PgServiceWorkerService_Factory() { return new PgServiceWorkerService(i0.ɵɵinject(i1.SwUpdate), i0.ɵɵinject(i2.UtilsService), i0.ɵɵinject(i0.NgZone)); }, token: PgServiceWorkerService, providedIn: "root" });
// NOTE: implementazione come descitto da google
export let deferredAddToHomePrompt = null;
function isInStandaloneMode() {
    return window.matchMedia('(display-mode: standalone)').matches;
}
export function restartBrowserApp() {
    window.location.href = '/';
    window.location.reload(true);
}
