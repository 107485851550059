import {Component, Input, QueryList, ViewChildren} from '@angular/core';
import {IonCheckbox, ModalController} from '@ionic/angular';
import axios from "axios";
import {BadRequest, InternalServerError, NotFound} from "ts-httpexceptions";
import {UtilsService} from "../utils.service";

export interface IMultiSelectAjaxComponentItem {
    id: string;
    name: string;
}

@Component({
    selector: 'pg-multiselect-dialog',
    templateUrl: './multi-select-ajax.component.html',
})

export class MultiSelectAjaxComponent  {

    @Input() public name: string = null;
    @Input() public title: string = null;
    @Input() public endpoint: string = null;
    @Input() private selected: any = [];
    @Input() public minChars: number = 3;

    @ViewChildren('MyCheckbox') private checkboxes: QueryList<IonCheckbox>;
    public filteredItems: Array<IMultiSelectAjaxComponentItem> = [];
    public sSelected: any = [];
    public label = 'Sel.';
    public isBusy: boolean = false;

    constructor(private modalController: ModalController, private utilsService: UtilsService) {
    }

    async ionViewDidEnter() {
        this.doReset();

        Object.assign(this.sSelected, this.selected);
    }

    async loadItems (search: string = null) {
        const endpoint = this.utilsService.getEndpoint() + this.endpoint;

        if (!this.isBusy) {
            this.isBusy = true;
            await axios.post(endpoint, {search: search}, {timeout: 5000})
                .then(response => {
                    this.filteredItems = response.data.data[this.name];
                    this.isBusy = false;
                })
                .catch(error => {
                    let msg = (error.response) ?
                        error.response.data.errors[Object.keys(error.response.data.errors)[0]].search : 'La ricerca sta impiegando troppo tempo, inserisci più caratteri.';
                    this.utilsService.showToast(msg, {color: "danger"})
                    this.isBusy = false;
                });
        }
    }

    public doReset() {
        this.filteredItems = [];
    }

    public clicked = (item) => {
        let cb = () => {
            if (this.doChecked(item.id)) {
                let i = -1;
                this.sSelected.map((v, k) => {
                    if (v['id'] == item.id) {
                        i = k;
                    }
                });
                if (i != -1) {
                    this.sSelected.splice(i, 1);
                }
            } else {
                this.sSelected.push({id: item.id, name: item.name});
            }
        }
        setTimeout(() => { return cb();}, 100);
    };

    doChecked(id: string) {
        return this.sSelected.some(e=>e['id'] == id);
    }

    async doChange($event) {
        let str = $event.target.value.toLowerCase();
        if (str.length >= this.minChars) await this.loadItems(str);
    }

    async doAbort() {
        await this.modalController.dismiss(this.selected,'abort');
    }

    async doApply() {
        let cb = async () => {
            if (this.sSelected.length === 0) {
                await this.modalController.dismiss([], 'all');
                return;
            }
            await this.modalController.dismiss(this.sSelected, 'confirm');
        }
        setTimeout(() => { return cb(); }, 100);
    }

}
