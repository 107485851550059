<ion-header>
	<ion-toolbar>

		<ion-buttons slot="start" *ngIf="canSelectAll">
			<ion-button (click)="doSelectAll()">
				<ion-icon slot="icon-only" name="done-all"></ion-icon>
				Tutti
			</ion-button>
		</ion-buttons>

		<ion-title *ngIf="title">{{title}}</ion-title>

		<ion-buttons slot="end">
			<ion-button (click)="doAbort()">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>

	</ion-toolbar>

	<ion-toolbar>
		<ion-searchbar placeholder="Ricerca..." debounce="250" (ionClear)="doReset()" (ionChange)="doChange($event)"></ion-searchbar>
	</ion-toolbar>


</ion-header>

<ion-content>

	<ion-list>
		<ion-radio-group [value]="selected">
			<ion-item *ngFor="let item of filteredItems">
				<ion-radio #MyCheckbox (click)="doChecked(item.id)" [value]="item.id">{{item.text}}</ion-radio>
				<ion-label text-wrap>&nbsp;{{item.text}}</ion-label>
				<ion-img *ngIf="item.src" class="image" src="{{item.src}}"></ion-img>
			</ion-item>
		</ion-radio-group>
	</ion-list>

</ion-content>

<ion-footer>
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-button expand="block" color="medium" fill="outline" (click)="doAbort()">Annulla</ion-button>
			</ion-col>
			<ion-col>
				<ion-button expand="block" color="success" [disabled]="!hasSomeChecked()" (click)="doApply()">Applica</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-footer>
