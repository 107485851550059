import {Component, Input, QueryList, ViewChildren} from '@angular/core';
import {IonCheckbox, ModalController} from '@ionic/angular';

export interface IMultiSelectComponentItem {
    id: string;
    text: string;
}

@Component({
    selector: 'pg-multiselect-dialog',
    templateUrl: './single-select.component.html',
    styles: ['.image {--border-radius: 5px;width: 100% !important;height: 100% !important;max-width: 150px !important;max-height: 100px !important;}']
})

export class SingleSelectComponent  {

    @Input() public title: string = null;
    @Input() private items: Array<IMultiSelectComponentItem>;
    @Input() public selected: string;
    @Input() public canSelectAll: boolean = false;

    @ViewChildren('MyCheckbox') private checkboxes: QueryList<IonCheckbox>;
    public filteredItems: Array<IMultiSelectComponentItem> = [];

    constructor(private modalController: ModalController) {
    }


    ionViewDidEnter() {
        this.doReset();
    }


    public doReset() {
        this.filteredItems = this.items;
    }



    doChecked(id: string) {
        this.selected = id;
    }


    doChange($event) {
        let s = $event.target.value.toLowerCase();
        console.log(s,  this.items);
        this.filteredItems = this.items.filter(v => {
            if (v.text != null && v.text.length > 0) {
                return v.text.toLowerCase().indexOf(s) >= 0;
            }
        });
    }


    async doAbort() {
        await this.modalController.dismiss(null,'abort');
    }

    hasSomeChecked = (): boolean => {
        return this.selected != '';
    };

    async doApply() {
        let res: string = this.selected;
        await this.modalController.dismiss(res,'confirm');
    }


    async doSelectAll() {
        await this.modalController.dismiss(null,'all');
    }


}
