import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { RepositoryService } from "../../surveys/repository.service";
import { UtilsService } from "../utils.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Comuni, Province, Regioni } from "../../surveys/surveys.interface";
import { ModalController } from "@ionic/angular";
export class RegionsComponent {
    constructor(surveysRepository, utilsService, fb, modalController) {
        this.surveysRepository = surveysRepository;
        this.utilsService = utilsService;
        this.fb = fb;
        this.modalController = modalController;
        this.regions = [];
        this.provinces = [];
        this.cities = [];
        this.getRegioni = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.regions = yield this.surveysRepository.getRegioni();
            }
            catch (err) {
                yield this.utilsService.showToast("Errore durante il recupero delle regioni", { color: "warning" });
            }
        });
        this.getProvinceByRegione = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.provinces = [];
            this.cities = [];
            yield this.form.patchValue({ provinces: [''], cities: [''] });
            let regions = this.form.value.regions;
            try {
                if (regions != '' && regions.length > 0 && regions[0] != '') {
                    regions = [regions];
                    this.provinces = yield this.surveysRepository.getProvinceByRegione(regions);
                    console.log(regions);
                }
            }
            catch (err) {
                yield this.utilsService.showToast("Errore durante il recupero delle province", { color: "warning" });
            }
        });
        this.getComuniByProvincia = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.cities = [];
            yield this.form.patchValue({ cities: [''] });
            let provinces = this.form.value.provinces;
            try {
                if (provinces != '' && provinces.length > 0 && provinces[0] != '') {
                    provinces = [provinces];
                    this.cities = yield this.surveysRepository.getComuniByProvincia(provinces);
                    console.log(provinces);
                }
            }
            catch (err) {
                yield this.utilsService.showToast("Errore durante il recupero dei comuni", { color: "warning" });
            }
        });
        this.form = this.fb.group({
            regions: [''],
            provinces: [''],
            cities: [''],
        });
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.utilsService.showLoading("Caricamento Filtri");
            yield this.getRegioni();
            yield this.utilsService.hideLoading();
        });
    }
    doAbort() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.modalController.dismiss(null, 'abort');
        });
    }
    ;
    doApply() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let res = this.form.getRawValue();
            console.log(res);
            let regioni = 'Tutte';
            res.regions = [res.regions];
            if (res.regions != '' && res.regions.length > 0 && res.regions[0] != "") {
                regioni = '';
                this.regions.forEach((v, k) => {
                    res.regions.forEach((rv, rk) => {
                        if (v['id'] == rv) {
                            regioni += v['name'] + ', ';
                        }
                    });
                    /*if (v['id'] == res.regions) {
                        regioni += v['name'] + ', ';
                    }*/
                });
            }
            let province = 'Tutte';
            res.provinces = [res.provinces];
            if (res.provinces != '' && res.provinces.length > 0 && res.provinces[0] != "") {
                province = '';
                this.provinces.forEach((v, k) => {
                    res.provinces.forEach((rv, rk) => {
                        if (v['id'] == rv) {
                            province += v['name'] + ', ';
                        }
                    });
                    /*if (v['id'] == res.provinces) {
                        province += v['name'] + ', ';
                    }*/
                });
            }
            let comuni = 'Tutti';
            res.cities = [res.cities];
            if (res.cities != '' && res.cities.length > 0 && res.cities[0] != "") {
                comuni = '';
                this.cities.forEach((v, k) => {
                    res.cities.forEach((rv, rk) => {
                        if (v['id'] == rv) {
                            comuni += v['name'] + ', ';
                        }
                    });
                    /*if (v['id'] == res.cities) {
                        comuni += v['name'] + ', ';
                    }*/
                });
            }
            yield this.modalController.dismiss({ regions: regioni.replace(/,\s*$/, ""), provinces: province.replace(/,\s*$/, ""), cities: comuni.replace(/,\s*$/, ""), res: res }, 'confirm');
        });
    }
}
